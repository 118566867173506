@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Open Sans, system-ui, sans-serif;
  }

  ul, ol {
    list-style: revert;
  }
}

/**
 * react-datepicker overrides
 */

.react-datepicker__week-number {
  @apply text-green-600 italic font-semibold;
}

.react-datepicker__day--outside-month {
  @apply text-gray-500;
}

.react-datepicker__day--disabled {
  @apply text-gray-300;
}

.react-datepicker__day--today {
  @apply font-black;
}

.react-datepicker__day--selected {
  @apply bg-blue-400;
}
