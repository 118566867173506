.line-graph__container {
  display: grid;
  grid-template-columns:[vertical-labels] auto [left-filler] 2rem [data] 1fr [right-filler] 2rem [end];
  grid-template-rows:[legend] auto [top-filler] 1rem [data] 1fr [bottom-filler] 0.5rem [horizontal-labels] auto [end];
}

.line-graph__legend {
  grid-column: left-filler/span 3;
  grid-row: legend/span 1;
}

.line-graph__left-line {
  grid-column: vertical-labels/span 1;
  grid-row: data/span 1;
}

.line-graph__bottom-line {
  grid-column: left-filler/end;
  grid-row: horizontal-labels/span 1;
}

.line-graph__vertical-labels-column {
  grid-column: vertical-labels/span 1;
  grid-row: data/span 1;
}

.line-graph__horizontal-lines {
  grid-column: left-filler/end;
  grid-row: data/span 1;
}

.line-graph__horizontal-labels-row {
  grid-column: data/span 1;
  grid-row: labels/span 1;
}

.line-graph__data {
  grid-column: data/span 1;
  grid-row: data/span 1;
}

.line-graph__hover {
  grid-column: data/span 1;
  grid-row: data/span 2;
}
