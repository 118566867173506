.main-grid {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "logo top"
    "left content";
}

.main-grid-logo {
  grid-area: logo;
}

.main-grid-top {
  grid-area: top;
}

.main-grid-left {
  grid-area: left;
}

.main-grid-content {
  grid-area: content;
  overflow: auto;
}
